import { Injectable } from '@angular/core';
import { DTO } from '@models';
import dayjs from 'dayjs';

@Injectable({
    providedIn: 'root'
})
export class GgStateService {
    public serverTimeInterval = null;
    public serverTime: dayjs.Dayjs = dayjs.utc();
    public expiredResendTime: dayjs.Dayjs;
    public passwordRules: DTO.FrontOffice.Auth.CreationConfigInfo;
    public registerTempEmail: string;

    public get isExpiredResendTime() {
        return !this.remainingTime;
    }

    public get remainingTime() {
        if (!this.expiredResendTime) {
            return '';
        }

        const diffTime = this.expiredResendTime.diff(this.serverTime, 'second');

        if (diffTime <= 0) {
            return '';
        }
        if (diffTime <= 60) {
            return `${diffTime}s`;
        }

        return `${Math.floor(diffTime / 60)} : ${diffTime % 60}`;
    }

    public setRemainingTime(remainingSecond: number) {
        this.serverTime = dayjs.utc();
        clearInterval(this.serverTimeInterval);
        this.serverTimeInterval = setInterval(() => {
            this.serverTime = this.serverTime.add(1, 'seconds');
        }, 1000);

        this.expiredResendTime = this.serverTime.add(remainingSecond, 'second');
    }
}
