import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { DTO } from '@models';
import { PlayerStateService } from '@core/states/player-state.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgIf } from '@angular/common';
import { SanitizerService } from '@shared/states/sanitizer.service';
import { environment } from '@environments/environment';
import { ChangeAvatarComponent } from '@shared/components/member/change-avatar/change-avatar.component';
import { DialogEditAvatarComponent } from '@shared/components/member/profile/dialog-edit-avatar/dialog-edit-avatar.component';
import { AccountType } from '@models/dto/front-office/auth';

@Component({
    selector: 'app-my-avatar',
    standalone: true,
    imports: [NgIf, ChangeAvatarComponent, DialogEditAvatarComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './my-avatar.component.html',
    styleUrl: './my-avatar.component.scss'
})
export class MyAvatarComponent implements OnDestroy {
    @Input() public isEditable: boolean = false;
    @Input() public size: 'small' | 'large' = 'large';
    public playerInfo: DTO.FrontOffice.Member.MemberInfo;
    public ggPassInfo: DTO.FrontOffice.Auth.GGPassInfo;
    public imgHost = environment.imageHost;
    public defaultProfileImage = `${environment.imageHost}/staking/common/avatar/default-avatar.svg`;
    public isShowChangeAvatar: boolean = false;
    private unsubscribe = new Subject<void>();

    public constructor(private playerStateService: PlayerStateService, private cdRef: ChangeDetectorRef, private sanitizerService: SanitizerService) {
        this.playerStateService.info.pipe(takeUntil(this.unsubscribe)).subscribe((playerInfo) => {
            this.playerInfo = playerInfo;
            this.cdRef.markForCheck();
        });
        this.playerStateService.ggPassInfo.pipe(takeUntil(this.unsubscribe)).subscribe((ggPassInfo) => {
            this.ggPassInfo = ggPassInfo;
            this.cdRef.markForCheck();
        });
    }

    public get isAvatarEditable() {
        return this.isEditable && this.ggPassInfo?.accountType !== AccountType.SPONSORED_PRO;
    }

    public sanitizeImageUrl(resource: string) {
        return this.sanitizerService.sanitizeImageUrl(resource);
    }

    public successChangeAvatar() {
        this.playerStateService.updatePlayerInfo();
        this.playerStateService.getGGPassInfo();
        this.cdRef.markForCheck();
    }

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
