import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DTO } from '@models';
import { map, tap } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { ServerTimeService } from '@core/services/server-time.service';
import { AvatarInfo, AvatarType, OPTokenKey } from '@models/dto/front-office/auth';

@Injectable({
    providedIn: 'root'
})
export class GGPassService {
    public constructor(private http: HttpClient, private serverTimeService: ServerTimeService) {}

    // Geetest
    public getGeetestInfo() {
        return this.http.get<DTO.FrontOffice.Auth.GetGeetestInfoResponse>(`/GGPass/Geetest`, {}).toPromise();
    }

    // 비번 룰
    public getCreationConfig() {
        return this.http.get<DTO.FrontOffice.Auth.GetCreationConfigResponse>(`/GGPass/Configs/Creation`, {}).toPromise();
    }

    // require password error, 비번 변경 때
    public getHighestPriorityCreationConfig(data?: { opAccessToken?: string }) {
        return this.http.post<DTO.FrontOffice.Auth.GetCreationConfigResponse>(`/GGPass/Configs/Creation/HighestPriority`, data || {}, {}).toPromise();
    }

    public resetPasswordSendVerificationCode(data: DTO.FrontOffice.Auth.ResetPasswordSendEmailRequest) {
        return this.http.post<DTO.FrontOffice.Auth.ResetPasswordSendEmailResponse>(`/GGPass/Passwords/Reset/Send`, data).toPromise();
    }

    public resetPasswordVerifyCode(data: DTO.FrontOffice.Auth.SendVerifyCodeValidationRequest) {
        return this.http.post<DTO.FrontOffice.Auth.SendVerifyCodeValidationResponse>(`/GGPass/Passwords/Reset/Verify`, data).toPromise();
    }

    public resetPassword(data: DTO.FrontOffice.Auth.ResetPasswordRequest) {
        return this.http.put<void>(`/GGPass/Passwords/Reset`, data).toPromise();
    }

    public sendVerifySignUp(data: DTO.FrontOffice.Auth.SendVerifySignUpRequest) {
        return this.http.post<DTO.FrontOffice.Auth.SendVerifySignUpResponse>(`/GGPass/CheckCredentials`, data).toPromise();
    }

    public signUp(data: DTO.FrontOffice.Auth.SignUpRequest) {
        return this.http.post<DTO.FrontOffice.Auth.LoginResponse>(`/GGPass/SignUp`, data).toPromise();
    }

    // 토큰 발급
    public login(data: DTO.FrontOffice.Auth.LoginRequest) {
        return this.http
            .post<DTO.FrontOffice.Auth.LoginResponse>('/GGPass/Login', data, { observe: 'response' })
            .pipe(
                tap((res) => {
                    this.serverTimeService.current = dayjs.utc(res.headers.get('Date'));
                }),
                map((res) => res.body)
            )
            .toPromise();
    }

    // refresh token
    public refreshToken(data: DTO.FrontOffice.Auth.ReFreshTokenRequest) {
        return this.http.post<DTO.FrontOffice.Auth.LoginResponse>(`/GGPass/Tokens/Refresh`, data).toPromise();
    }

    public socialLogin(data: { opTokenSubject: string; opAccessToken: string; mfaCode?: string; clientInfo: DTO.FrontOffice.Auth.ClientInfo }) {
        return this.http.post<DTO.FrontOffice.Auth.LoginResponse>('/GGPass/Login/Social', data).toPromise();
    }

    public sendMFAForLogin(data: { opAccessToken: string }) {
        return this.http.post('/GGPass/Login/MFA/Send', data).toPromise();
    }

    public setPasswordRequired(data: { opAccessToken: string; newPassword: string; clientInfo: DTO.FrontOffice.Auth.ClientInfo }) {
        return this.http.put<DTO.FrontOffice.Auth.LoginResponse>('/GGPass/Login/Passwords/RequiredChange', data).toPromise();
    }

    public setLoginEmailRequired(data: { email: string; verificationCode?: string; opTokenKey: OPTokenKey; clientInfo: DTO.FrontOffice.Auth.ClientInfo }) {
        return this.http.put<DTO.FrontOffice.Auth.LoginResponse>('/GGPass/Login/Emails/Required', data).toPromise();
    }

    // GGPass Info
    public getGGPassInfo() {
        return this.http.get<DTO.FrontOffice.Auth.GetGGPassInfoResponse>(`/GGPass`, {}).toPromise();
    }

    public setGGPassAgreement(data: { isAgreement: boolean }) {
        return this.http.put<void>(`/GGPass/Agreement`, data).toPromise();
    }

    public checkNickname(data: { nickname: string }) {
        return this.http.get<void>(`/GGPass/Nicknames/Availability?nickname=${data.nickname}`).toPromise();
    }

    public setNicknameForMigration(data: { nickname: string }) {
        return this.http.put<void>(`/GGPass/Nicknames/Migration`, data).toPromise();
    }

    public setNickname(data: { nickname: string }) {
        return this.http.put<void>(`/GGPass/Nicknames`, data).toPromise();
    }

    public getAvatars() {
        return this.http.get<{ avatars: AvatarInfo[] }>(`/GGPass/Avatars`).toPromise();
    }

    public setAvatar(avatarInfo: AvatarInfo) {
        return this.http.put<void>(`/GGPass/Avatars`, avatarInfo).toPromise();
    }

    public setCustomAvatar(avatarFile: Blob) {
        const formData = new FormData();
        formData.append('avatarFile', avatarFile, 'avatar.png');
        return this.http.post<void>(`/GGPass/Avatars/Custom`, formData).toPromise();
    }

    public setNicknameAvatar(avatarBlob: Blob | null, data: { nickname: string; avatarType: AvatarType; avatarId: string }) {
        const formData = new FormData();
        if (avatarBlob) {
            formData.append('avatarFile', avatarBlob);
        }
        formData.append('data', JSON.stringify(data));
        return this.http.put<void>(`/GGPass`, formData).toPromise();
    }

    public checkCustomAvatar(avatarBlob: Blob) {
        const formData = new FormData();
        formData.append('avatarFile', avatarBlob);
        return this.http.post<void>(`/GGPass/Avatars/Custom/Check`, formData).toPromise();
    }

    public setPassword(data: DTO.FrontOffice.Auth.SetPasswordRequest) {
        return this.http.put<void>(`/GGPass/Passwords`, data).toPromise();
    }

    public setMFA(data: DTO.FrontOffice.Auth.SetMFARequest) {
        return this.http.put<DTO.FrontOffice.Auth.SetMFAResponse>(`/GGPass/MFA`, data).toPromise();
    }

    public setMFAGoogle(data: { totpCode: string }) {
        return this.http.put<void>(`/GGPass/MFA/Google`, data).toPromise();
    }

    public sendMFA() {
        return this.http.post<void>(`/GGPass/MFA/Send`, {}).toPromise();
    }

    public logout() {
        return this.http.post<void>(`/GGPass/Logout`, {}).toPromise();
    }
}
