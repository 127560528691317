import { Injectable } from '@angular/core';
import { DTO } from '@models';
import { GgStateService } from '@shared/states/gg-state.service';
import { Password } from '@helpers/password';
import { ConfirmationService, MessageService } from 'primeng/api';
import dayjs from 'dayjs';
import { Router } from '@angular/router';

declare let window: Window;

@Injectable({
    providedIn: 'root'
})
export class GGPassErrorService {
    public constructor(private router: Router, private ggStateService: GgStateService, private messageService: MessageService, private confirmationService: ConfirmationService) {}

    public GGPassErrorHandler(errorParameters: string) {
        const errorJson = JSON.parse(errorParameters);
        const code: DTO.Enums.Common.GGPassErrorCodes = errorJson?.code;

        if (!code) {
            this.messageService.add({
                severity: 'error',
                summary: 'Server Error',
                detail: 'Error has been encountered. Please try again.'
            });

            return;
        }

        switch (code) {
            case DTO.Enums.Common.GGPassErrorCodes.INTERNAL_ERROR:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Internal Error',
                    detail: 'Error has been encountered. Please try again.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.INVALID_REQUEST: {
                this.confirmationService.confirm({
                    header: 'Invalid Request',
                    message: 'Unexpected error has been encountered. Please try again or contact customer service for further assistance.',
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            }
            case DTO.Enums.Common.GGPassErrorCodes.DOMAIN_NOT_ALLOWED:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Domain Not Allowed',
                    detail: 'To protect the safety of your account, we do not accept disposable emails.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.USERNAME_ALREADY_EXISTS:
                this.confirmationService.confirm({
                    header: 'Existing Account',
                    message: `<p class="text-green"><strong>${this.ggStateService.registerTempEmail}</strong></p><br>The entered email already exists.<br>Would you like to go to the sign in page?`,
                    rejectLabel: 'Cancel',
                    acceptLabel: 'Sign In',
                    accept: () => {
                        this.router.navigateByUrl('/auth/login');
                    }
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.INVALID_USERNAME:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invalid Username',
                    detail: 'Entered email address is invalid.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.INVALID_COUNTRY_CODE:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invalid Country Code',
                    detail: 'Entered country is invalid.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.INVALID_PASSWORD_LENGTH: {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invalid Password Length',
                    detail: `Password must be between ${this.ggStateService.passwordRules?.passwordMinLength || 8} and ${this.ggStateService.passwordRules?.passwordMaxLength || 20} characters.`
                });
                break;
            }
            case DTO.Enums.Common.GGPassErrorCodes.INVALID_PASSWORD_PATTERN:
                this.confirmationService.confirm({
                    header: 'Invalid Password Format',
                    message: `<div class="password-rules-desc-in-modal">Passwords must include:
                            <ul>
                                <li>${this.ggStateService.passwordRules.passwordMinLength}~${this.ggStateService.passwordRules.passwordMaxLength} characters</li>
                                <li>${Password.passwordRulesMessage(this.ggStateService.passwordRules)}</li>
                            </ul></div>`,
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.PASSWORD_NOT_ALLOWED_INCLUDE_USERNAME:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Email Not Allowed in Passwords',
                    detail: 'Password must not contain word(s) included in your email address.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.REQUIRED_TNC_AGREEMENT:
                // 회원가입때 T&C동의 필요 : 발생하지 않음.
                break;
            case DTO.Enums.Common.GGPassErrorCodes.INVALID_SIGNUP_TOKEN: {
                this.confirmationService.confirm({
                    header: 'New Code Required',
                    message: 'You must proceed with a new verification code. <br />Please click the Resend button to receive a new verification code.',
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            }
            case DTO.Enums.Common.GGPassErrorCodes.RESEND_TERM_LIMITED: {
                const remainingTime = JSON.parse(errorJson.description)?.remainingTime || 0;

                this.messageService.add({
                    severity: 'error',
                    summary: 'Resend Restricted',
                    detail: `Resend is allowed after ${remainingTime} seconds.`
                });
                this.ggStateService.setRemainingTime(remainingTime);
                break;
            }
            case DTO.Enums.Common.GGPassErrorCodes.RESEND_LIMITED: {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Resend Restricted',
                    detail: 'Failed to send verification code. Please try again later.'
                });

                this.ggStateService.setRemainingTime(60 * 60);
                break;
            }
            case DTO.Enums.Common.GGPassErrorCodes.SENDGRID_ERROR:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invalid Email Address',
                    detail: 'Entered email address is invalid.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.REQUIRED_VERIFICATION_CODE:
                // TODO : signup, reset password, set OnePass MFA페이지에서발생. 해당 플로우 인증번호 입력 화면으로 이동함, 해당 페이지에서 처리
                break;
            case DTO.Enums.Common.GGPassErrorCodes.INVALID_VERIFICATION_CODE:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invalid Verification Code',
                    detail: 'The code entered is invalid.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.UNMATCHED_VERIFICATION_CODE:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Unmatched Verification Code',
                    detail: 'The code entered is invalid.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.USED_VERIFICATION_CODE:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Already Used Verification Code',
                    detail: 'The code entered has expired.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.EXPIRED_VERIFICATION_CODE:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Expired Verification Code',
                    detail: 'The code entered has expired.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.PASSWORD_ATTEMPTS_EXCEEDED:
                this.confirmationService.confirm({
                    header: 'Invalid Request',
                    message: 'Your account has been locked because you failed to enter your password numerous times<br />Click the Forgot Password below to reset your password.',
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.ONEPASS_NOT_FOUND:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invalid Account',
                    detail: 'Entered email address or password is invalid.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.ONEPASS_SIGNUP_NOT_FOUND:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Account Not Found',
                    detail: 'The entered account does not exist.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.BRAND_SITE_NOT_FOUND:
                this.confirmationService.confirm({
                    header: 'Unexpected Error',
                    message: 'Unexpected error occurred. Please contact customer service for further assistance.',
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.INVALID_PASSWORD:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invalid Password',
                    detail: 'The password entered is invalid. Please check your password and try again.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.REQUIRED_MFA_CODE:
                // TODO : 로그인 화면에서 MFA 처리해야함
                break;
            case DTO.Enums.Common.GGPassErrorCodes.INVALID_TOTP_CODE:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invalid Code',
                    detail: 'Entered verification code does not match.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.ONEPASS_SUSPENDED: {
                const description = JSON.parse(errorJson.description);
                const startedAt = description?.startedAt;
                const finishedAt = description?.finishedAt;

                this.confirmationService.confirm({
                    header: 'Suspended',
                    message: `Your account was suspended on 
                    <p class="text-green">${dayjs.utc(startedAt).format('YYYY-MM-DD, HH:mm')} until ${dayjs.utc(finishedAt).format('YYYY-MM-DD, HH:mm')} (UTC+0).</p>
                    Please contact <a href="mailto:help@pokerstake.com" class="link-in-confirm">help@pokerstake.com</a> for further guidance.`,
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            }
            case DTO.Enums.Common.GGPassErrorCodes.ONEPASS_BLOCKED: {
                const startedAt = JSON.parse(errorJson.description)?.startedAt;
                this.confirmationService.confirm({
                    header: 'Blocked',
                    message: `Your account was suspended on 
                        <p class="text-green">${dayjs.utc(startedAt).format('YYYY-MM-DD, HH:mm')} (UTC+0).</p>
                        Please contact <a href="mailto:help@pokerstake.com" class="link-in-confirm">help@pokerstake.com</a> for further guidance.`,
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            }
            case DTO.Enums.Common.GGPassErrorCodes.ONEPASS_CLOSED: {
                const startedAt = JSON.parse(errorJson.description)?.startedAt;
                this.confirmationService.confirm({
                    header: 'Closed',
                    message: `Your account was closed on 
                        <p class="text-green">${dayjs.utc(startedAt).format('YYYY-MM-DD, HH:mm')} (UTC+0).</p>
                        Please contact <a href="mailto:help@pokerstake.com" class="link-in-confirm">help@pokerstake.com</a> for further guidance.`,
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            }
            case DTO.Enums.Common.GGPassErrorCodes.REQUIRED_SOCIAL_LOGIN: {
                let oauthType = String(JSON.parse(errorJson.description)?.oauthType || '').toLowerCase();
                oauthType = oauthType.charAt(0).toUpperCase() + oauthType.slice(1);

                this.confirmationService.confirm({
                    header: `Continue with ${oauthType}`,
                    message: `Your account has been registered via ${oauthType}.<br />Please sign in using ${oauthType}.`,
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            }
            case DTO.Enums.Common.GGPassErrorCodes.ONEPASS_TIME_LOCK:
                this.confirmationService.confirm({
                    header: 'Account Locked',
                    message: 'Your account has been locked for security reasons. <br />Please wait and try again later.',
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.ONEPASS_RESET_PASSWORD_LOCK:
                this.confirmationService.confirm({
                    header: 'Account Locked',
                    message: 'Your account has been locked for security reasons. <br />To unlock it, please reset your password.',
                    accept: () => {
                        window.location.href = '/auth/password-reset/forgot';
                    }
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.ONEPASS_TIME_OR_RESET_PASSWORD_LOCK:
                this.confirmationService.confirm({
                    header: 'Account Locked',
                    message: 'Your account has been locked for security reasons.<br />To unlock it, please reset your password or try again later.',
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.REFRESH_TOKEN_NOT_FOUND:
            case DTO.Enums.Common.GGPassErrorCodes.REFRESH_TOKEN_EXPIRED:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Login Error',
                    detail: 'Error has been encountered. Please try again.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.AVATAR_PROHIBITED:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Avatar Prohibited',
                    detail: 'The requested avatar is not available. Please choose a different avatar.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.ONEPASS_ACCOUNT_NOT_FOUND:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Account Not Found',
                    detail: 'Account was not found.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.ONEPASS_ACTIVE_ACCOUNT_ALREADY_EXISTS:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Account Already Exists',
                    detail: 'Entered account already exists.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.ONEPASS_ACCOUNT_ALREADY_EXISTS:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Account Already Exists',
                    detail: 'Entered GGPass account already exists.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.BRAND_NOT_FOUND:
            case DTO.Enums.Common.GGPassErrorCodes.BRAND_SITE_SECURITY_CONFIG_NOT_FOUND:
                this.confirmationService.confirm({
                    header: 'Unexpected Error',
                    message: 'Unexpected error has been encountered. Please contact customer service for further assistance.',
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.INVALID_SEC_CODE:
            case DTO.Enums.Common.GGPassErrorCodes.INVALID_RECAPTCHA:
            case DTO.Enums.Common.GGPassErrorCodes.RECAPTCHA_TIMEOUT:
                this.confirmationService.confirm({
                    header: 'CAPTCHA Error',
                    message: 'A CAPTCHA error has occurred. Please try again.',
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.RECAPTCHA_INTERNAL_ERROR:
            case DTO.Enums.Common.GGPassErrorCodes.BRAND_SITE_TEMPLATE_CONFIG_NOT_FOUND:
            case DTO.Enums.Common.GGPassErrorCodes.ACCESS_DENIED:
                this.confirmationService.confirm({
                    header: 'Unexpected Error',
                    message: 'Unexpected error has been encountered. Please try again or contact customer service for further assistance.',
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.ONEPASS_ACCOUNT_BRAND_SITE_ALREADY_EXISTS:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Account Already Exists',
                    detail: 'Entered account already exists.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.NICKNAME_PROHIBITED:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Prohibited Nickname',
                    detail: 'Entered nickname is prohibited. Please enter a different nickname.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.AVATAR_NOT_FOUND:
            case DTO.Enums.Common.GGPassErrorCodes.AVATAR_SUSPENDED:
                this.messageService.add({
                    severity: 'error',
                    detail: 'The requested avatar is not available. Please choose a different one.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.NICKNAME_ALREADY_EXISTS:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Nickname Already Exists',
                    detail: 'Entered nickname already exists. Please enter a different nickname.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.NICKNAME_ALREADY_SET:
                this.messageService.add({
                    severity: 'error',
                    detail: 'The nickname has already been set.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.USERNAME_NOT_FOUND:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Username Not Found',
                    detail: 'The entered username does not exist.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.ONEPASS_ACCOUNT_CITIZENSHIP_NOT_ALLOWED:
                this.confirmationService.confirm({
                    header: 'Citizenship Not Allowed',
                    message: 'Requested citizenship change is not permitted. Please select a different citizenship.',
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.INVALID_CURRENT_PASSWORD:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invalid Current Password',
                    detail: 'Entered current password is invalid.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.PASSWORD_SAME_AS_OLD:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Same Password',
                    detail: 'New password must be different from current password.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.INVALID_USER:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invalid User',
                    detail: 'The email or password entered is invalid. Please check and try again.'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.VERIFICATION_CODE_ATTEMPTS_EXCEEDED:
                this.confirmationService.confirm({
                    header: 'Verification Code Expired',
                    message: 'You have exceeded the number of verification code input, and the code has expired. <br/> Please click the Resend button to receive a new verification code.',
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.GGPASS_EMAIL_EMPTY:
                this.confirmationService.confirm({
                    header: 'Contact Support',
                    message: 'You cannot login to WSOP+ because your account does not have an email.<br />Please contact <a href="mailto:help@pokerstake.com" class="link-in-confirm">help@pokerstake.com</a> for further guidance.',
                    rejectVisible: false,
                    acceptLabel: 'Ok'
                });
                break;
            case DTO.Enums.Common.GGPassErrorCodes.TELESIGN_INVALID_PHONE_NUMBER:
                this.messageService.add({
                    severity: 'error',
                    summary: 'Invalid Phone Number',
                    detail: 'The phone number entered is invalid.'
                });
                break;
            default:
                this.messageService.add({
                    severity: 'error',
                    summary: errorJson.message || 'Server Error'
                });
                break;
        }
    }
}
