import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class SanitizerService {
    public constructor(private sanitizer: DomSanitizer, @Inject(DOCUMENT) private document: Document) {}

    public sanitizeImageUrl(resource: string) {
        if (!resource) {
            return '';
        }

        const isCdnResource = resource.includes('//cdn.pocketfives.com') || resource.includes(environment.imageHost);
        return isCdnResource ? resource : this.sanitizer.bypassSecurityTrustResourceUrl(resource);
    }

    public getSanitizeHtml(value: string) {
        const textarea = this.document.createElement('textarea');
        textarea.innerHTML = value;
        return textarea.value;
    }
}
