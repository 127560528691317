import { Inject, Injectable } from '@angular/core';
import { ClientInfo } from '@models/dto/front-office/auth';
import { DOCUMENT } from '@angular/common';
import UAParser from 'ua-parser-js';
import { v1 as uuidv1 } from 'uuid';

export const guidStorageKey = 'additionalAuthorizationStore';
export const clientInfoStorageKey = 'clientStore';

@Injectable({
    providedIn: 'root'
})
export class ClientInfoService {
    public readonly clientInfo: ClientInfo;

    public constructor(@Inject(DOCUMENT) private document: Document) {
        const clientInfoStorage = document.defaultView.localStorage.getItem(clientInfoStorageKey);
        if (!clientInfoStorage) {
            // clientInfo 안에 guid 를 저장해 더 이상 필요 없음
            let guid = document.defaultView.localStorage.getItem(guidStorageKey);

            if (!guid) {
                guid = uuidv1();
            } else {
                // guid 저장 제거
                document.defaultView.localStorage.removeItem(guidStorageKey);
            }

            const parser = new UAParser();
            const result = parser.getResult();
            this.clientInfo = {
                clientDeviceType: 'mobile' === result.device.type ? 'MOBILE' : 'PC',
                clientDeviceIdNew: guid,
                clientOsType: result.os.name.toUpperCase(),
                clientOsVersion: `${result.os.name} ${result.os.version} ${result.cpu.architecture || ''}`,
                clientDeviceName: result.device.vendor || result.browser.name,
                clientDeviceModel: result.device.model || 'browser'
            };
            document.defaultView.localStorage.setItem(clientInfoStorageKey, JSON.stringify(this.clientInfo));
            return;
        }

        this.clientInfo = JSON.parse(clientInfoStorage);
    }
}
