import { AbstractControl, FormGroup } from '@angular/forms';
import { DTO } from '@models';

export class Password {
    /**
     * 비밀번호 컨펌
     */
    public static matchingPasswordValid(password, confirmPassword) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[password];
            const matchingControl = formGroup.controls[confirmPassword];
            if (matchingControl.errors && !matchingControl.errors.isNotMatch) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ isNotMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    /**
     * 비밀번호 룰 체크
     */
    public static passwordValidator(control: AbstractControl, passwordRules: DTO.FrontOffice.Auth.CreationConfigInfo) {
        if (control.value?.length && control.value?.length < passwordRules.passwordMinLength) {
            return { message: `Password must be between ${passwordRules.passwordMinLength} and ${passwordRules.passwordMaxLength} characters.` };
        }
        if (control.value?.length && control.value?.length > passwordRules.passwordMaxLength) {
            return { message: `Password must be between ${passwordRules.passwordMinLength} and ${passwordRules.passwordMaxLength} characters.` };
        }

        if (!!control.value) {
            const includeAlphabetRegex = /[a-zA-Z]/;
            if (passwordRules.passwordIncludeAlphabet && !includeAlphabetRegex.test(control.value)) {
                return { message: `Password must include alphabet.` };
            }

            const includeUpperLowerCaseRegex = /(?=.*[a-z])(?=.*[A-Z])/;
            if (passwordRules.passwordIncludeBothUpperLowerCase && !includeUpperLowerCaseRegex.test(control.value)) {
                return { message: `Password must include both uppercase and lowercase alphabets.` };
            }

            const includeNumberRegex = /[0-9]/;
            if (passwordRules.passwordIncludeNumber && !includeNumberRegex.test(control.value)) {
                return { message: `Password must include number.` };
            }

            const includeSpecialCharRegex = /[^a-zA-Z0-9]/;
            if (passwordRules.passwordIncludeSpecialCharacter && !includeSpecialCharRegex.test(control.value)) {
                return { message: `Password must include special character.` };
            }
        }
    }

    public static passwordRulesMessage(passwordRules: DTO.FrontOffice.Auth.CreationConfigInfo) {
        const rules = [];
        if (passwordRules.passwordIncludeAlphabet) {
            if (passwordRules.passwordIncludeBothUpperLowerCase) {
                rules.push('Alphabet(Upper, Lower)');
            } else {
                rules.push('Alphabet');
            }
        }
        if (passwordRules.passwordIncludeNumber) {
            rules.push('number');
        }
        if (passwordRules.passwordIncludeSpecialCharacter) {
            rules.push('special character');
        }
        const resultRules = rules.join(', ');
        return resultRules ? resultRules.charAt(0).toUpperCase() + resultRules.slice(1) : '';
    }
}
