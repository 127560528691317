import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { DTO } from '@models';
import { DOCUMENT } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ServerTimeService } from './server-time.service';

@Injectable({
    providedIn: 'root'
})
export class PlayerService {
    public constructor(@Inject(DOCUMENT) protected document: Document, private http: HttpClient, private serverTimeService: ServerTimeService, private messageService: MessageService) {}

    // 유저 정보 호출
    public getMemberInfo(memberId: number) {
        return this.http.get<DTO.FrontOffice.Member.GetMyInfoResponse>(`/Members/${memberId}`, {}).toPromise();
    }

    public getPlayerBalance() {
        return this.http
            .post<DTO.FrontOffice.Balance.GetMemberBalanceResponse>('/Balance/GetMemberBalanceRequest', {}, { observe: 'response' })
            .pipe(
                tap((res) => {
                    this.serverTimeService.current = dayjs.utc(res.headers.get('Date'));
                }),
                map((res) => res.body)
            )
            .toPromise();
    }

    public async goToPokerCraftProfile(originType: DTO.Enums.Common.OriginType, sellerId: number) {
        if (originType === DTO.Enums.Common.OriginType.P5) {
            return;
        }
        const pokerCraftWindow = this.document.defaultView.window.open('');
        try {
            const pokerCraftUrl = await this.http.get(`/Pokercraft/Url?sellerId=${sellerId}`, { responseType: 'text' }).toPromise();

            if (pokerCraftUrl) {
                pokerCraftWindow.location.href = pokerCraftUrl;
            } else {
                pokerCraftWindow.close();
            }
        } catch (e) {
            pokerCraftWindow.close();
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Fail to Load!'
            });
        }
    }

    public getSellerStakeProfile(data: { sellerId: number }) {
        return this.http.post<DTO.FrontOffice.Seller.GetSellerStakeProfileResponse>(`/Stake/GetSellerStakeProfile`, data).toPromise();
    }
}
