import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PhoneMaskDirective } from '@shared/directives/phone-mask.directive';
import { SwiperDirective } from './swiper.directive';
import { DigitOnlyDirective } from './digit-only.directive';
import { CardExpiryFormatDirective } from './card-expiry-format.directive';
import { ClipboardDirective } from './clipboard.directive';

@NgModule({
    imports: [CommonModule, DigitOnlyDirective, PhoneMaskDirective, CardExpiryFormatDirective, ClipboardDirective, SwiperDirective],
    exports: [DigitOnlyDirective, CardExpiryFormatDirective, ClipboardDirective, SwiperDirective]
})
export class DirectiveModule {}
