<ng-container *ngIf="isLoading; else loaded">
    <div class="avatar-loading">
        <p-progressSpinner class="spinner" [style]="{ width: '50px', height: '50px' }" strokeWidth="2" fill="none" role="progressbar" ariaLabel="Loading" animationDuration="1s"></p-progressSpinner>
    </div>
</ng-container>
<ng-template #loaded>
    <menu class="avatar-filter">
        <ul>
            <li *ngFor="let filter of filterOptions" [class.active]="filter === filterTag">
                <button type="button" (click)="handleFilter(filter)">{{ formatFilterTag(filter) }}</button>
            </li>
        </ul>
    </menu>

    <section class="avatar-list">
        <ul>
            <li *ngFor="let avatarItem of filteredAvatarList" [class.active]="avatar?.avatarId === avatarItem.avatarId || (!avatarItem.avatarId && avatar?.type === AvatarType.CUSTOM)">
                <button type="button" (click)="chooseAvatar(avatarItem)">
                    <ng-container *ngIf="!avatarItem.avatarId">
                        <div class="avatar-image">
                            <img [src]="customImageUrl || customButtonImage" alt="" />
                        </div>
                        <span class="avatar-name">Custom</span>
                    </ng-container>
                    <ng-container *ngIf="avatarItem.avatarId">
                        <div class="avatar-image">
                            <img [src]="avatarItem.url" alt="{{ avatarItem.name }}" />
                        </div>
                        <span class="avatar-name">{{ avatarItem.name }}</span>
                    </ng-container>
                </button>
            </li>
        </ul>
    </section>

    <p-dialog [(visible)]="cropDialogVisible" header="Crop Image" [style]="{ width: '336px' }" [modal]="true" [closable]="false" [draggable]="false" [resizable]="false" class="dialog-crop">
        <section class="crop-contents">
            <div class="crop-area">
                <app-crop-image
                    #cropImage
                    (changeFile)="handleChangeCustomFile()"
                    (loadError)="handleCustomFileError()"
                    [config]="{
                        aspectRatio: 1,
                        minCropBoxWidth: 200,
                        minContainerWidth: 304,
                        minContainerHeight: 280,
                        viewMode: 1,
                        dragMode: 'move',
                        cropBoxMovable: false,
                        center: false,
                    }"
                />
            </div>
            <div class="button-area">
                <button pButton type="button" class="btn btn-secondary" label="Cancel" (click)="handleClose()"></button>
                <button pButton type="button" class="btn" label="Crop" (click)="handleCrop()" [loading]="isLoadingCrop" [disabled]="isLoadingCrop"></button>
            </div>
        </section>
    </p-dialog>
</ng-template>
