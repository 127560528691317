import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { DTO, VM } from '@models';
import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';
import { GGPassService } from '@core/services/ggpass.service';
import type { ClientInfo } from '@models/dto/front-office/auth';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class PlayerStateService {
    private readonly IS_LOGIN_KEY = 'isLogin';
    private readonly IS_REMEMBER_KEY = 'isRememberMe';

    public isSignedIn = new BehaviorSubject<boolean>(!!this.cookieService.get(this.IS_LOGIN_KEY) || false);
    public info = new BehaviorSubject<DTO.FrontOffice.Member.MemberInfo>(null);
    public balance = new BehaviorSubject<VM.UpdateUserBalance>({ value: 0, isUpdate: false });
    public accessToken = new BehaviorSubject<string>(null);
    public ggPassInfo = new BehaviorSubject<DTO.FrontOffice.Auth.GGPassInfo>(null);

    public constructor(private router: Router, private ggPassService: GGPassService, private authService: AuthService, private cookieService: CookieService, private messageService: MessageService) {
        this.isSignedIn.subscribe((isSignedIn) => {
            if (isSignedIn) {
                this.cookieService.set(this.IS_LOGIN_KEY, 'true', { expires: this.cookieService.get(this.IS_REMEMBER_KEY) ? 30 : null, path: '/', sameSite: 'Strict' });
            } else {
                this.accessToken.next(null);
                this.cookieService.delete(this.IS_LOGIN_KEY, '/');
                this.cookieService.delete(this.IS_REMEMBER_KEY, '/');
                this.info.next(null);
                this.balance.next({ value: 0, isUpdate: false });
            }
        });
    }

    public async updatePlayerInfo() {
        try {
            const playerInfo = await this.authService.getMyInfo();

            this.info.next(playerInfo);
        } catch (e) {
            console.error(e);
        }
    }

    public async signIn(loginResponse: DTO.FrontOffice.Auth.LoginResponse, isRememberCookie = false) {
        // 로그인 유지 체크 > cookie 저장 expire time 유무
        if (isRememberCookie) {
            this.cookieService.set(this.IS_REMEMBER_KEY, 'true', { expires: 30, path: '/', sameSite: 'Strict' });
        }
        this.isSignedIn.next(true);
        this.accessToken.next(loginResponse.accessToken);
        this.ggPassInfo.next(loginResponse.ggPassInfo);
        await this.updatePlayerInfo();
    }

    public async signOut() {
        try {
            await this.ggPassService.logout();
            this.isSignedIn.next(false);
        } catch (e) {
            console.log(e);
        }
    }

    // 토큰 재발급
    public async refreshToken(clientInfo: ClientInfo) {
        try {
            const res = await this.ggPassService.refreshToken({ clientInfo });
            if (!this.isSignedIn.getValue()) {
                this.isSignedIn.next(true);
            }
            this.accessToken.next(res.accessToken);
            this.ggPassInfo.next(res.ggPassInfo);

            await this.updatePlayerInfo();

            return true;
        } catch (e) {
            console.error(e);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: e.error.description ?? `Your session has expired.<br />Please log in again.`
            });
            this.isSignedIn.next(false);
            this.router.navigateByUrl('/');
            return false;
        }
    }

    public async getGGPassInfo() {
        try {
            const res = await this.ggPassService.getGGPassInfo();
            this.ggPassInfo.next(res.ggPassInfo);
        } catch (e) {
            console.error(e);
        }
    }
}
