<nav class="navbar" *ngIf="isInitialized" [class.login]="isSignedIn">
    <ul class="navbar-menu">
        <li *ngIf="isSignedIn && playerInfo" class="nav-item mobile-account-menu dropdown" (mouseleave)="closeSubMenu()">
            <a href="#account" (click)="$event.preventDefault(); openSubMenu(p5HasSubMenu.Account)">Account <i class="fas fa-angle-right"></i></a>
            <app-account-menu [isInNavBar]="true" [isOpen]="selectedSubMenu === p5HasSubMenu.Account" (closeSubMenu)="closeSubMenu()" (closeAccountMenu)="onCloseSideMenu()"></app-account-menu>
        </li>
        <li class="nav-item">
            <a [class.active]="isOnSponsorshipMenu" (click)="$event.preventDefault(); onCloseSideMenu()" routerLink="/staking/poker-staking" href="#staking">Staking</a>
        </li>
        <li class="nav-item">
            <a [class.active]="isOnArticleMenu" (click)="$event.preventDefault(); onCloseSideMenu()" routerLink="/news" href="#pokerStories">Poker Stories</a>
        </li>
        <li *ngIf="isSignedIn && playerInfo" class="nav-item mobile-signout"><a (click)="$event.preventDefault(); onCloseSideMenu(); logout()" href="#logout">Sign Out</a></li>
    </ul>
</nav>
