import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { AvatarInfo, AvatarType } from '@models/dto/front-office/auth';
import { ChangeAvatarComponent } from '@shared/components/member/change-avatar/change-avatar.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { FormControl } from '@angular/forms';
import { GGPassService } from '@core/services/ggpass.service';
import { PlayerStateService } from '@core/states/player-state.service';
import { NgIf } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-dialog-edit-avatar',
    standalone: true,
    imports: [ChangeAvatarComponent, DialogModule, ButtonModule, NgIf, ProgressSpinnerModule],
    templateUrl: './dialog-edit-avatar.component.html',
    styleUrl: './dialog-edit-avatar.component.scss'
})
export class DialogEditAvatarComponent {
    @Input() public isShowModal = false;
    @Output() public closeModal = new EventEmitter();
    @Output() public successChangeAvatar = new EventEmitter();

    public isLoading = false;
    public avatar = new FormControl<AvatarInfo>(null);

    public constructor(private ggPassService: GGPassService, private cdRef: ChangeDetectorRef, private playerStateService: PlayerStateService, private messageService: MessageService) {
        const avatarExtend = this.playerStateService.ggPassInfo.getValue()?.avatarExtend;
        this.avatar.setValue({ avatarId: avatarExtend.avatarId, type: avatarExtend.type, url: avatarExtend.url } as AvatarInfo);
        this.cdRef.markForCheck();
    }

    public changeAvatar(avatar) {
        this.avatar.setValue(avatar);
        this.avatar.markAsDirty();
        this.cdRef.markForCheck();
    }

    public close() {
        this.closeModal.emit();
    }

    public async submitChangeAvatar() {
        try {
            this.isLoading = true;
            if (AvatarType.CUSTOM === this.avatar.value.type) {
                await this.ggPassService.setCustomAvatar(this.avatar.value.blob);
            } else {
                await this.ggPassService.setAvatar(this.avatar.value);
            }

            this.successChangeAvatar.emit();

            this.messageService.add({
                severity: 'success',
                summary: 'Avatar',
                detail: 'Your avatar has been successfully changed.'
            });
            this.close();
        } catch (e) {
            console.error('Fail to load!', e);
        } finally {
            this.isLoading = false;
        }
    }
}
