import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AngularCropperjsModule, CropperComponent } from 'angular-cropperjs';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-crop-image',
    standalone: true,
    imports: [AngularCropperjsModule, NgIf],
    templateUrl: './crop-image.component.html',
    styleUrl: './crop-image.component.scss'
})
export class CropImageComponent {
    @Input() public image: string;
    @Input() public config = {};
    @Input() public croppedWidth = 200;
    @Input() public croppedHeight = 200;
    @Input() public croppedQuality: 'low' | 'medium' | 'high' = 'high';

    @Output() public changeFile = new EventEmitter();
    @Output() public loadError = new EventEmitter();

    @ViewChild('fileInput') public fileInput: ElementRef;
    @ViewChild('angularCropper') public angularCropper: CropperComponent;

    public get cropper() {
        return this.angularCropper.cropper;
    }

    public chooseFile() {
        this.fileInput.nativeElement.click();
    }

    protected handleChangeFileInput(event: Event) {
        const target = event.target as HTMLInputElement;
        const file = target.files?.[0];
        if (!file) {
            return;
        }

        this.image = URL.createObjectURL(file);
        target.value = '';

        setTimeout(() => {
            if (this.angularCropper?.loadError) {
                this.loadError.emit();
                this.image = undefined;
                return;
            }

            this.changeFile.emit(this.image);
        }, 10);

        setTimeout(() => {
            this.angularCropper?.cropper.setCropBoxData({ top: 20 });
        }, 250);
    }

    public async crop(props?: { close?: boolean }): Promise<Blob> {
        return new Promise((resolve, reject) => {
            try {
                const canvas = this.cropper.getCroppedCanvas({
                    width: this.croppedWidth,
                    height: this.croppedHeight,
                    imageSmoothingQuality: this.croppedQuality
                });
                canvas.toBlob(
                    (blob) => {
                        resolve(blob);
                    },
                    'image/jpeg',
                    1
                );
            } catch (e) {
                console.error(e);
                reject(e);
                this.loadError.emit();
            } finally {
                if (props?.close) {
                    this.image = null;
                }
            }
        });
    }

    public close() {
        this.image = null;
    }
}
