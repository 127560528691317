import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT, NgIf } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import dayjs from 'dayjs';
import { filter, map } from 'rxjs/operators';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TimerService } from '@core/services/timer.service';
import { HeaderComponent } from '@shared/components/layout/header/header.component';
import { FooterComponent } from '@shared/components/layout/footer/footer.component';
import { MaintenanceService } from '@core/services/maintenance.service';
import { SignalRService } from '@core/services/signalR.service';
import { ServerTimeService } from '@core/services/server-time.service';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, ConfirmDialogModule, RouterOutlet, ToastModule, HeaderComponent, FooterComponent, NgIf],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly externalLayoutRoutes = ['external'];
    private subscription: Subscription;
    public externalPageLayout = false;
    public isFooterVisible = false;

    public get isMaintenancePage() {
        const current = this.document.location.pathname.split('/')[1];
        return current.includes('maintenance');
    }

    public constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private timer: TimerService,
        private maintenanceService: MaintenanceService,
        private titleService: Title,
        private signalRService: SignalRService,
        private serverTimeService: ServerTimeService
    ) {
        console.log(`%c️🃏PokerStake🔥%c release version - ${environment.appVersion}`, 'font-weight: bold; color: #bc0000; font-size: 30px;', 'font-weight: bold; font-size: 15px;');
    }

    public ngOnInit() {
        this.setPageTitle();
        this.setExternalPageLayout();
        this.timer.start();
        setTimeout(() => {
            this.setMaintenance();
            this.connectSignalR();
        }, 1);
    }

    private setPageTitle() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event: NavigationEnd) => {
                    let route: ActivatedRoute = this.router.routerState.root;
                    let routeTitle = '';
                    while (route!.firstChild) {
                        route = route.firstChild;
                    }

                    if (!event.url.includes('poker-staking')) {
                        this.document.defaultView.scrollTo(0, 0);
                    }
                    this.isFooterVisible = true;

                    if (route.snapshot.data['title']) {
                        routeTitle = route!.snapshot.data['title'];
                    }
                    return routeTitle;
                })
            )
            .subscribe((title: string) => {
                if (title) {
                    this.titleService.setTitle(`PokerStake ${title}`);
                }
            });
    }

    private async connectSignalR() {
        await this.signalRService.initSignalR();
    }

    // 메인터넌스 처리
    private async setMaintenance() {
        await this.maintenanceService.maintenanceCheck();

        // 점검 구독 > config 업데이트는 signalR 에서
        this.maintenanceService.config.subscribe((maintenance) => {
            // if (!maintenance.gg.isMaintenance && !maintenance.wsopca.isMaintenance) {
            //     this.callGGToken();
            // }
            if (!maintenance) {
                return;
            }

            const diffMaintenanceTime = dayjs.utc(maintenance.p5.startDate).diff(this.serverTimeService.current) + 2000;
            setTimeout(
                () => {
                    if (this.maintenanceService.isP5Maintenance() && !maintenance.isInDevIp) {
                        this.router.navigate(['maintenance']);
                    }
                },
                diffMaintenanceTime > 0 ? diffMaintenanceTime : 0
            );
        });
    }

    private setExternalPageLayout() {
        const current = this.document.location.pathname.split('/')[1];
        this.externalPageLayout = this.externalLayoutRoutes.includes(current);
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
