import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DTO } from '@models';
import { map, tap } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { ServerTimeService } from '@core/services/server-time.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public constructor(private http: HttpClient, private serverTimeService: ServerTimeService) {}

    // 인증메일 전송(코인출금)
    public sendVerificationEmailRequest(data: DTO.FrontOffice.Common.SendVerificationEmailRequest) {
        return this.http
            .post<DTO.FrontOffice.Common.SendVerificationEmailResponse>(`/Common/SendVerificationEmailRequest`, data, { observe: 'response' })
            .pipe(
                tap((res) => {
                    this.serverTimeService.current = dayjs.utc(res.headers.get('Date'));
                }),
                map((res) => res.body)
            )
            .toPromise();
    }

    public getMyInfo() {
        return this.http.get<DTO.FrontOffice.Member.GetMyInfoResponse>(`/Members/MyInfo`, {}).toPromise();
    }
}
