import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { JsonPipe, NgForOf, NgIf } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CropImageComponent } from '@shared/components/crop-image/crop-image.component';
import { DialogModule } from 'primeng/dialog';
import { AvatarExtendInfo, AvatarInfo, AvatarType } from '@models/dto/front-office/auth';
import { environment } from '@environments/environment';
import { GGPassService } from '@core/services/ggpass.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageService } from 'primeng/api';
import { ErrorCodes } from '@models/dto/enums/common';
import { GGPassErrorService } from '@shared/states/ggpass-error.service';

@Component({
    selector: 'app-change-avatar',
    standalone: true,
    imports: [NgForOf, NgIf, ButtonModule, CropImageComponent, DialogModule, ProgressSpinnerModule, JsonPipe],
    templateUrl: './change-avatar.component.html',
    styleUrl: './change-avatar.component.scss'
})
export class ChangeAvatarComponent implements OnInit {
    @Input() public avatar: AvatarInfo | AvatarExtendInfo;
    @Output() public changeAvatar = new EventEmitter<AvatarInfo>();
    @Output() public failLoadAvatars = new EventEmitter();
    public imgHost = environment.imageHost;
    public isLoading = true;
    public isLoadingCrop = false;
    public cropDialogVisible = false;
    public customButtonImage = `${this.imgHost}/auth/icon-custom-btn.png`;
    public customImageBlob: Blob;
    public customImageUrl: string;
    public avatarList: AvatarInfo[];
    public filterTag: string = 'All';

    @ViewChild('cropImage') public cropImage: CropImageComponent;

    public get filterOptions() {
        if (!this.avatarList) {
            return [];
        }
        const tags = this.avatarList.reduce((acc, avatar) => {
            if (!avatar.tag) {
                return acc;
            }
            acc[avatar.tag] = true;
            return acc;
        }, {});
        const specialAvatars = this.avatarList.some((avatar) => avatar.type === AvatarType.SPECIAL) ? ['Special'] : [];
        return ['All', ...Object.keys(tags), ...specialAvatars];
    }

    public get filteredAvatarList(): AvatarInfo[] {
        if ('All' === this.filterTag) {
            return this.avatarList;
        }
        if ('Special' === this.filterTag) {
            return this.avatarList.filter((avatar) => {
                return avatar.type === AvatarType.SPECIAL;
            });
        }

        return this.avatarList.filter((avatar) => {
            return this.filterTag === avatar.tag;
        });
    }

    public constructor(private ggPassService: GGPassService, private cdRef: ChangeDetectorRef, private messageService: MessageService, private ggPassErrorService: GGPassErrorService) {}

    public async ngOnInit() {
        try {
            const response = await this.ggPassService.getAvatars();

            if (response) {
                this.avatarList = [
                    {
                        avatarId: 0,
                        type: AvatarType.CUSTOM,
                        tag: '',
                        name: 'CUSTOM',
                        url: '',
                        createdAt: '',
                        isEnabled: true,
                        isBordered: false
                    },
                    ...response.avatars
                ];
                if (this.avatar?.type === AvatarType.CUSTOM) {
                    this.customImageUrl = this.avatar.url;
                }
                this.cdRef.markForCheck();
            }
        } catch (e) {
            console.error(e);
            if (ErrorCodes.GGPASS_ERROR === e.error?.errorCode) {
                this.ggPassErrorService.GGPassErrorHandler(e.error.errorParameters);
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: e.error?.description ?? 'An error occurred while fetching avatars.'
                });
            }
            this.failLoadAvatars.emit();
        } finally {
            this.isLoading = false;
        }
    }

    public formatFilterTag(tag: string) {
        return tag
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (c) => c.toUpperCase());
    }

    public handleFilter(tag: string) {
        this.filterTag = tag;
    }

    public handleChangeCustomFile() {
        this.cropDialogVisible = true;
    }

    public handleCustomFileError() {
        this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred while loading the image.'
        });
    }

    public handleClose() {
        this.cropImage.close();
        this.cropDialogVisible = false;
        this.cdRef.markForCheck();
    }

    public async handleCrop() {
        if (this.isLoadingCrop) {
            return;
        }
        try {
            this.isLoadingCrop = true;

            const blob = await this.cropImage.crop();
            await this.ggPassService.checkCustomAvatar(blob);

            this.customImageBlob = blob;
            this.customImageUrl = URL.createObjectURL(blob);

            this.changeAvatar.emit({
                avatarId: null,
                type: AvatarType.CUSTOM,
                tag: '',
                name: 'CUSTOM',
                url: this.customImageUrl,
                blob: this.customImageBlob,
                createdAt: new Date().toISOString(),
                isEnabled: true,
                isBordered: false
            });
        } catch (e) {
            console.error(e);
            if (ErrorCodes.GGPASS_ERROR === e.error?.errorCode) {
                this.ggPassErrorService.GGPassErrorHandler(e.error.errorParameters);
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: e.error?.description ?? 'An error occurred while checking the image.'
                });
            }
        } finally {
            this.isLoadingCrop = false;
            this.handleClose();
        }
    }

    public chooseAvatar(avatar: AvatarInfo) {
        if (avatar.type === AvatarType.CUSTOM) {
            this.cropImage.chooseFile();
            return;
        }

        this.changeAvatar.emit(avatar);
    }

    protected readonly AvatarType = AvatarType;
}
