<p-dialog class="dialog-secondary" [visible]="isShowModal" (visibleChange)="close()" [closable]="false" [position]="'center'" header="Select an Avatar" [modal]="true" [style]="{ width: '360px' }" [draggable]="false" [resizable]="false">
    <div class="avatar-area">
        <app-change-avatar [avatar]="avatar.value" (changeAvatar)="changeAvatar($event)" (failLoadAvatars)="close()"></app-change-avatar>
    </div>

    <section class="button-area">
        <button (click)="close()" pButton type="button" class="btn btn-secondary" label="Cancel"></button>
        <button (click)="submitChangeAvatar()" [disabled]="!avatar.dirty" [loading]="isLoading" pButton type="button" class="btn" label="Confirm"></button>
    </section>

    <div class="progress-spinner-area" *ngIf="isLoading">
        <p-progressSpinner class="spinner" [style]="{ width: '50px', height: '50px' }" strokeWidth="2" fill="none" animationDuration="1s" role="progressbar" ariaLabel="Loading"></p-progressSpinner>
    </div>
</p-dialog>
