import { MFAMethod, SecurityLevel } from '@models/dto/enums/common';

export enum AccountType {
    SPONSORED_PRO = 'SPONSORED_PRO',
    STREAMER = 'STREAMER',
    INFLUENCER = 'INFLUENCER',
    GG_MODERATOR = 'GG_MODERATOR',
    REAL_NAME = 'REAL_NAME',
    NORMAL = 'NORMAL'
}

export enum AvatarType {
    DEFAULT = 'DEFAULT',
    SPECIAL = 'SPECIAL',
    CUSTOM = 'CUSTOM'
}

export interface GeetestInfo {
    status: number;
    success: number;
    gt: string;
    challenge: string;
    newCaptcha: boolean;
    msg: string;
}

export interface GetGeetestInfoResponse {
    geetest: GeetestInfo;
}

export interface GeeTestParams {
    challenge?: string;
    secCode?: string;
}

export interface CaptchaInfo extends GeeTestParams {
    recaptchaToken?: string;
    action?: string;
}

export interface CreationConfigInfo {
    brandSiteOnepassCreationConfigId: number;
    brandSiteId: number;
    loginType: string;
    passwordMinLength: number;
    passwordMaxLength: number;
    passwordIncludeAlphabet: boolean;
    passwordIncludeBothUpperLowerCase: boolean;
    passwordIncludeNumber: boolean;
    passwordIncludeSpecialCharacter: boolean;
    passwordAllowedUsername: boolean;
    passwordSecurityLevel: number;
}

export interface ResetPasswordSendEmailRequest {
    email: string;
    captcha: CaptchaInfo; // Google or Geetest
}

export interface ResetPasswordSendEmailResponse {
    userName: string;
    expiredAt: string;
}

export interface SendVerifyCodeValidationRequest {
    email: string;
    verificationCode: string;
}

export interface SendVerifyCodeValidationResponse {
    opAccessToken: string;
    creationConfig: CreationConfigInfo;
}

export interface ResetPasswordRequest {
    opAccessToken: string;
    email: string;
    password: string;
    verificationCode: string;
}

export interface GetCreationConfigResponse {
    config: CreationConfigInfo;
}

export interface SendVerifySignUpRequest {
    email: string;
    password: string;
    captcha: CaptchaInfo; // Google or Geetest
}

export interface SendVerifySignUpResponse {
    signUpToken: string;
}

export interface SendVerificationEmailRequest {
    recaptchaToken: string;
}

export interface SendVerificationEmailResponse {
    verificationId: number;
    expiredAt: string;
}

export interface ClientInfo {
    clientDeviceIdNew: string;
    clientDeviceName: string;
    clientDeviceModel: string;
    clientDeviceType: 'PC' | 'MOBILE';
    clientOsType: string;
    clientOsVersion?: string;
}

export interface SignUpRequest {
    email: string;
    password: string;
    verificationCode: string;
    signUpToken: string;
    clientInfo: ClientInfo;
}

export interface NFTWalletInfo {
    onPassNftWalletId: number;
    walletAddress: string;
    avatarId: number;
    contractAddress: string;
    tokenId: string;
}

export interface AvatarInfo {
    avatarId: number;
    type: AvatarType;
    tag?: string;
    name: string;
    url: string;
    blob?: Blob;
    isBordered: boolean;
    isEnabled: boolean;
    nftWallet?: NFTWalletInfo;
    createdAt: string;
}

export interface AvatarExtendInfo {
    avatarId: number;
    type: AvatarType;
    url: string;
    isBordered: boolean;
    isEnabled: boolean;
    isNFT: boolean;
    hasBadges: boolean;
}

export interface BadgeInfo {
    onePassBadgeId: number;
    badgeId: number;
    requesterId: string;
    requestId: string;
}

export interface MFAInfo {
    methodType: MFAMethod;
    methodValue: string;
    level: SecurityLevel;
    isEnabled: boolean;
    isVerified: boolean;
}

export interface GGPassInfo {
    isAgreement: boolean;
    accountType: AccountType;
    nickname: string;
    additionalNicknames: string[];
    initializedAt: string;
    isNicknameEnabled: boolean;
    isPasswordSet: boolean;
    avatarExtend: AvatarExtendInfo;
    mfas: MFAInfo[];
}

export interface GetGGPassInfoResponse {
    ggPassInfo: GGPassInfo;
}

export interface LoginRequest {
    email: string;
    password: string;
    mfaCode?: string;
    clientInfo: ClientInfo;
    isRememberMe: boolean;
}

export interface OPTokenKey {
    onePassAlias: string;
    clientDeviceId: string;
}

export interface LoginResponse {
    isEmailSetRequired: boolean;
    isPasswordSetRequired: boolean;
    opAccessToken: string;
    opTokenKey: OPTokenKey;
    accessToken: string;
    ggPassInfo: GGPassInfo;
}

export interface ReFreshTokenRequest {
    clientInfo: ClientInfo;
}

export interface SetPasswordRequest {
    oldPassword: string;
    newPassword: string;
    verificationId: number;
    verificationCode: string;
}

export interface SetMFARequest {
    methodType: MFAMethod;
    level: SecurityLevel;
    countryCode?: string;
    mobileNumber?: string;
    verificationCode?: string;
}

export interface SetMFAResponse {
    qrCode: string;
    key: string;
}

export interface SocialCallbackResponse {
    tokenSubject: string;
    accessToken: string;
    oauthUserId: string;
    isPasswordSetRequired: 'true' | 'false';
    isBonusCodeRequired: 'true' | 'false';
    methodType: 'MOBILE' | 'GOOGLE' | 'EMAIL';
    methodValue: string;
    clientDeviceType: 'PC' | 'MOBILE';
    clientDeviceIdNew: string;
    clientOsType: string;
    clientOsVersion: string;
    brandId: 'PSK';
    schemeUrl: 'psk';
    redirect: 'true';
    userAgent: string;
    clientIp: string;
}
