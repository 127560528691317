import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks } from 'ng-lazyload-image';
import { TableModule } from 'primeng/table';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { SpinnerModule } from 'primeng/spinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { KeyFilterModule } from 'primeng/keyfilter';
import { FileUploadModule } from 'primeng/fileupload';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { RippleModule } from 'primeng/ripple';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { TabMenuModule } from 'primeng/tabmenu';
import { SkeletonModule } from 'primeng/skeleton';
import { NouisliderModule } from 'ng2-nouislider';
import { RecaptchaModule } from 'ng-recaptcha';
import { DirectiveModule } from './directives/directive.module';
import { PipeModule } from './pipes/pipe.module';
import { TooltipAdjsComponent } from './components/tooltip/tooltip-adjs/tooltip-adjs.component';
import { FacebookLikeButtonComponent } from './components/facebook-like-button/facebook-like-button.component';
import { MaintenanceNoticeComponent } from './components/layout/maintenance-notice/maintenance-notice.component';

const ANGULAR_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

const PRIMENG_MODULES = [
    TableModule,
    BreadcrumbModule,
    ProgressSpinnerModule,
    CalendarModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    MessageModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    SpinnerModule,
    RadioButtonModule,
    SelectButtonModule,
    InputSwitchModule,
    CheckboxModule,
    TooltipModule,
    AutoCompleteModule,
    OverlayPanelModule,
    KeyFilterModule,
    FileUploadModule,
    MenuModule,
    ToastModule,
    RippleModule,
    VirtualScrollerModule,
    InputNumberModule,
    InputTextareaModule,
    InputMaskModule,
    TabMenuModule,
    SkeletonModule
];

const EXTERNAL_MODULES = [NouisliderModule, LazyLoadImageModule];

@NgModule({
    imports: [...ANGULAR_MODULES, ...PRIMENG_MODULES, ...EXTERNAL_MODULES, PipeModule, DirectiveModule, RecaptchaModule, TooltipAdjsComponent, FacebookLikeButtonComponent, MaintenanceNoticeComponent],
    exports: [...ANGULAR_MODULES, ...PRIMENG_MODULES, ...EXTERNAL_MODULES, PipeModule, DirectiveModule, TooltipAdjsComponent, FacebookLikeButtonComponent, MaintenanceNoticeComponent, RecaptchaModule],
    providers: [[{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }]],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule {}
