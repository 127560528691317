import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DtPipe } from './dt.pipe';
import { MoneyPipe } from './money.pipe';
import { LocalDtPipe } from './local-dt.pipe';
import { CurrencySymbolPipe } from './currency-symbol.pipe';
import { SanitizerPipe } from './sanitizer.pipe';

@NgModule({
    imports: [CommonModule, DtPipe, MoneyPipe, LocalDtPipe, CurrencySymbolPipe, SanitizerPipe],
    exports: [DtPipe, MoneyPipe, LocalDtPipe, CurrencySymbolPipe, SanitizerPipe]
})
export class PipeModule {}
